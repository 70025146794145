.contiener-home{
    height: 100%;
    width: 100%;
}

.dashboard-header-conteiner{
    width: 100%;
    height: 82vh;
    position: relative;
    overflow: hidden;
}
.dashboard-header-conteiner-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: none;
}
.dashboard-header-conteiner-img-f{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.dashboard-header-conteiner-content{
    background-color: transparent;
    display: flex;
    position: absolute;
    top: 0; 
    left: 0; 
    width: 100%;
    height: 100%;
    z-index: 2; 
}
.dashboard-header-conteiner-content-left{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-header-conteiner-content-right{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}
.conteiner-content-img{
    width: 100%;
    height: 100%;
    object-fit: fill;
    margin-top: -1px;
    margin-left: -1px;
}
.content-text-header{
    width: 60%;
    height: auto;
}
.content-text-header-title{
    color: #320E6A;
    font-size: 3em;
    font-weight: 500;
}
.content-text-header-description{
    color: #320E6A;
    font-size: 1.2em;
    font-weight: 200;
}

.content-text-header-button{
    width: 100%;
    height: auto;
    margin-top: 50px;
}
.content-text-header-button button{
    background-color: transparent;
    border: 1.5px solid #320E6A;
    border-radius: 12px;
    width: 50%;
    height: 45px;
    color: #320E6A;
    margin-left: 20px;
    cursor: pointer;
    font-size: 1.2em;
}
.conteiner-promosdesc{
    width: 100%;
    height: 30vh;
    display: flex;
}
.conteiner-promosdesc-item{
    width: 33.333%;
    height: 100%;
    background-color: #ececec;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

}
.conteiner-desc{
    width: 90%;
    height: 90%;
    background-color: #320E6A;
    display: flex;
}
.conteiner-desc-left{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.conteiner-desc-right{
    width: 60%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ligth-conteiner{
    background-color: #dddddd;
}
.conteiner-desc-right img{
    width: auto;
    height: 100%;
    max-height: 90%;
}
.img-gift img{
    object-fit: cover !important;
}
.content-desc-text{
    width: 100%;
}
.content-desc-title{
    font-size: 1.6em;
    font-weight: 400;
    color: white;
    margin-left: 20px;
}
.content-desc-value{
    font-size: 1.3em;
    font-weight: 500;
    color: white;
    margin-top: 20px;
    margin-left: 20px;
}
.conteiner-liner-item{
    width: 95%;
    height: auto;
    border: 2px solid #320E6A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}
.conteiner-liner-title{
    width: 80%;
    font-size: 2em;
    font-weight: 400;
    color: #320E6A;
    text-align: center;
}
.conteiner-liner-description{
    margin-top: 20px;
    font-size: 1em;
    font-weight: 400;
    color: #000000;
    text-align: center;
}
.container-pag-newproducts{
    width: 100%;
    height: auto;
    display: none;
}
.pag-newproducts-head{
    width: 100%;
    height: auto;
    padding: 8px 0;
    display: flex;
}
.pag-newproducts-head-item{
    width: 33.33%;
    height: 45px;
    display: flex;
    align-items: center;
}
.item-center{
    justify-content: center;
}
.pag-newproducts-head-title{
    width: auto;
    height: auto;
    font-size: 1.3em;
    font-weight: 600;
    margin-left: 30px;
}
.pag-newproducts-item-button{
    width: 40px;
    height: 40px;
    border: 1.5px solid #54A5C0;
    border-radius: 20px;
    background-color: transparent;
    margin: 0 8px;
    cursor: pointer;
}
.pag-newproducts-item-button:hover{
    background-color: #54A5C0;
    color: white;
}
.pag-newproducts-item-button:hover .icon-button-pag{
    color: white;
}
.icon-button-pag{
    color: #54A5C0;
    width: 100%;
    height: 100%;
}





/* container-collection*/

.container-collection{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.collection-row-margin{
    margin-top: 100px;
}
.collection-row{
    width: 90%;
    height: 70vh;
    display: flex;
}

.collection-row-item{
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;    
}
.collection-row-item > img{
    width: 100%;
    height: 100%;
    background-color: #320E6A;
    object-fit: cover;
}

.collection-row-img-first:hover{
    transition: 0.9s ease-in-out;
    content: url('../../assets/img_hober_collection.png');
}
.collection-row-img-second:hover{
    transition: 0.9s ease-in-out;
    content: url('../../assets/img_hober_collection2.png');
}
.collection-bg{
    background-color: #EFEFEF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.collection-bg-white{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.collection-row-item-content{
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.row-item-content-title{
    width: 70%;
    height: auto;
    text-align: left;
    font-size: 1.5em;
    font-weight: bolder;
    margin-bottom: 30px;
}
.row-item-content-list-item{
    width: 73%;
    height: auto;
    font-size: 1.5em;
    font-weight: bolder;
    display: flex;
    align-items: center;
    margin: 4px 0;
}
.row-item-content-list-item > img{ 
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 24px;
}
.row-item-content-list-item-desc{
    font-size: 0.7em;
    font-weight: 300;
    margin-left: 10px;
}
.collection-row-item-button{
    margin-top: 20%;
    width: 50%;
    height: 50px;
    border: 1px solid #6f33d0;
    color: #6f33d0;
    background-color: transparent;
    border-radius: 10px;
    font-weight: bolder;
    font-size: 1em;
    cursor: pointer;
}

/* COLLAGE */

.container-collage{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container-collage-head{
    width: 100%;
    height: auto;
    margin-top: 3%;
    font-size: 1.8em;
    font-weight: 500;
    text-align: center;
}
.div-line{
    margin: 20px 0;
    width: 90%;
    background-color: #dddddd;
    height: 1px;
}
.collage-primary{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}
.collage-primary img{
    width: 90%;
    height: auto;
    display: block;
}
.overlay-section-one {
    position: absolute;
    top: 23%;
    left: 44%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    
  }
  .overlay-section-two {
    position: absolute;
    top: 80%;
    left: 44%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    
  }
  .overlay-section-three {
    position: absolute;
    top: 60%;
    left: 73%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    
  }
  .overlay-dot{
    width: 20px;
    height: 20px;
    background-color: #320E6A;
    border: 13px solid white;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
  }
  .overlay-conteiner{
    width: 200px;
    padding: 30px 20px;
    background-color: white;
    margin-bottom: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: none;
  }
  .overlay-section-one:hover .overlay-dot{
    border-color: #320E6A;
    background-color: white;
  }
  .overlay-section-one:hover .overlay-conteiner{
    display: block;
  }
  .overlay-section-two:hover .overlay-dot{
    border-color: #320E6A;
    background-color: white;
  }
  .overlay-section-two:hover .overlay-conteiner{
    display: block;
  }
  .overlay-section-three:hover .overlay-dot{
    border-color: #320E6A;
    background-color: white;
  }
  .overlay-section-three:hover .overlay-conteiner{
    display: block;
  }
.collage-secondary{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.collage-secondary img{
    width: 29%;
    height: auto;
    margin: 0 13px;
}
.collage-description{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.collage-description-item{
    width: 29%;
    height: auto;
    margin: 0 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: 600;
}


/* NUESTROS CLIENTES*/
.container-our-clients{
    width: 100%;
    height: 50vh;
}
.ourclients-list{
    width: 100%;
    height: 70%;
    background-color: #320E6A;
    margin-top: 3%;
}


/* TRABAJA CON NOSOTROS*/

.container-workonme{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-collage-subhead{
    width: 100%;
    height: auto;
    margin-top: 8px;
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5%;
}
.conteiner-form-map{
    width: 90%;
    height: 80vh;
    background-color: #EFEFEF;
    display: flex;
}
.conteiner-form-workme{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.form-contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.conteiner-map-workme{
    width: 60%;
    height: 100%;
}
.form-workme-title{
    font-size: 1.5em;
    font-weight: 800;
    width: 90%;
}

.checkbutton-conteiner{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.checkbutton-conteiner button{
    width: 90%;
    height: 45px;
    display: flex;
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.checkbutton-icon{
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
.button-conteiner{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.button-conteiner button{
    width: 60%;
    height: 45px;
    font-weight: 600;
    font-size: 1.1em;
    cursor: pointer;
    border: 1px solid #6f33d0;
    color: #6f33d0;
    border-radius: 10px;
}
.address-conteiner{
    text-align: center;
    margin-top: 20px;
    font-size: 0.9em;
    font-weight: 600;
    width: 80%;
}



/* UP BUTTON*/

.container-upbutton{
    width: 100%;
    height: auto;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #54A5C0;
}
.container-upbutton button{
    height: 50px;
    width: 50px;
    border: 2px solid #54A5C0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    margin-bottom: 10px;
}
.container-upbutton-icon{
    width: 40px;
    height: 40px;
    color: #54A5C0;
    cursor: pointer;
}

/* BANNER */
.container-banner{
    margin-top: 20px;
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.div-line-black{
    width: 100%;
    height: 1.2px;
    background-color: #000000;
}
.banner-body{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-body-left{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
}
.banner-body-right{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-body-title{
    width: 49%;
    height: auto;
    font-weight: bolder;
    font-size: 2.2em;
    margin: 0 8%;
}
.banner-body-sep{
    width: 1px;
    height: 60%;
    background-color: #e1ceff;
}
.banner-body-description{
    width: 49%;
    height: auto;
    font-size: 1.2em;
    margin: 0 8%;
    color: #6a6a6a;
}
.banner-subscribe-conteiner{
    width: 70%;
    height: 45px;
    border: 1.5px solid #320E6A;
    display: flex;
}
.banner-subscribe-conteiner > input{
    width: 70%;
    background-color: white;
    border: none;
}
.banner-subscribe-conteiner > button{
    width: 30%;
    background-color: #320E6A;
    border: none;
    color: white;
    font-size: 1em;
    font-weight: 700;
}

/* FOOTER */
.container-footer{
    width: 100%;
    height: 80vh;
    position: relative;
    overflow: hidden;
    background-color: #320E6A;
    display: flex;
    flex-direction: column;
}
.container-footer > img{
 object-fit: fill;   
}
.container-footer-image{
    width: 24%;
    height: auto;

    display: flex;
    position: absolute;
    top: 4%; 
    left: 4%; 
    z-index: 2;
}
.container-footer-image img{
    width: 100%;
    height: 100%;
}
.container-footer-text-one{
    width: 39%;
    height: auto;
    color: white;
    font-size: 1.5em;
    margin-top: 20%;
    margin-left: 4%;
    display: flex;
    z-index: 2;
}
.container-footer-text-two{
    width: 39%;
    height: auto;
    color: white;
    font-size: 1.5em;
    margin-top: 2%;
    margin-left: 4%;
    display: flex;
    z-index: 2;
}
.container-footer-text-three{
    width: 39%;
    height: auto;
    color: white;
    font-size: 1.5em;
    margin-left: 4%;
    display: flex;
    z-index: 2;
}
.dowm-text{
    margin-top: 8%;
    font-size: 1.2em;
}

.container-buttons-footer > button{
    margin: 0 10px;
    background-color: transparent;
    height: auto;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
}
.conteiner-footer-final{
    width: 90%;
    height: auto;
    display: flex;
    margin-left: 3%;
    z-index: 2;
}
.final-item-left{
    width: 50%;
    height: auto;
    display: flex;
    justify-content: left;
    align-items: center;
}
.final-item-rigth{
    width: 50%;
    height: auto;
    display: flex;
    align-items: end;
    flex-direction: column;
}
.container-buttons-socialmedia-footer{
    width: 45%;
    display: flex;
    justify-content: center;
}
.socialmedia-description{
    color: white;
}
.container-buttons-socialmedia-footer > button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}

/* MARCA DE AGUA*/
.marcauno{
    width: 25%;
    position: absolute;
    left: -140px;
    top: 172%;
    z-index: -1;
}

.marcados{
    width: 17%;
    position: absolute;
    right: -160px;
    top: 165%;
    z-index: -1;
}
.marcatres{
    width: 17%;
    position: absolute;
    left: -100px;
    top: 295%;
    z-index: 1;
}
.buttonWhatsApp{
    position: fixed;
    bottom: 50px;
    right: 80px;
    z-index: 1000;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    
    .buttonWhatsApp{
        bottom: 30px;
        right: 20px;
    }
    .conteiner-desc-right{
        height: 100%;
    }
    .conteiner-liner-title{
        font-size: 1.5em;
    }
    .dashboard-header-conteiner{
        height: 50vh;
    }
    .content-desc-title{
        font-size: 1em;
    }
    .conteiner-promosdesc{
        width: 100%;
        height: auto;
        flex-direction: column;
    }
    .conteiner-promosdesc-item{
        width: 100%;
        padding: 0;
        margin: 0;
        height: 180px;
    }
    .conteiner-liner-item{
        width: 90%;
        height: 100%;
        border: 2px solid #320E6A;
        border: none;
        margin: 0;
        padding: 0;
    }
    .banner-body-title{
        font-size: 1em;
    }
    .banner-body-description{
        font-size: 1em;
    }
    .banner-subscribe-conteiner{
        width: 90%;
    }
    .banner-subscribe-conteiner > input{
        width: 60%;
    }
    .banner-subscribe-conteiner > button{
        width: 40%;
    }
    .container-footer-text-one{
        font-size: 1.2em;
    }
    .container-footer-text-two{
        font-size: 1.2em;
    }
    .container-footer{
        height: 70vh;
    }
    .conteiner-form-workme{
        width: 50%;
    }
    .conteiner-map-workme{
        width: 50%;
    }
    .content-text-header-button button{
        width: 90%;
    }
    .content-text-header-title{
        font-size: 1.8em;
    }
}
@media screen and (max-height: 720px) {
    .conteiner-promosdesc-item{
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .conteiner-liner-item{
        width: 90%;
        height: 80%;
        border: 2px solid #320E6A;
        margin: 0;
        padding: 0;
    }
    .conteiner-content-img{
        height: 100%;
        width: auto;
    }
    .marcados{
        right: 100px;
    }
    .collection-row{
        height: 90vh;
    }
    .dashboard-header-conteiner{
        height: 90vh;
    }
    .banner-body-title{
        font-size: 1em;
    }
    .banner-body-description{
        font-size: 1em;
    }
    .container-footer{
        height: 720px;
    }
    .conteiner-form-map{
        height: 700px !important;
    }
    .content-desc-title{
        font-size: 1em;
    }
    .conteiner-liner-title{
        font-size: 1.2em;
    }
}
@media screen and (max-width: 720px) {
    .content-text-header-title{
        font-size: 2em;
        font-weight: 500;
        color: white;
    }
    .content-text-header-description{
        font-size: 1em;
        color: white;
    }
    .content-text-header{
        width: 80%;
    }
    .dashboard-header-conteiner-content-left{
        width: 100%;
    }
    .dashboard-header-conteiner-content-right{
        display: none;
    }

    .content-text-header-button button{
        border: 1.5px solid #fff;
        color: #fff;
    }

    /* Collection section*/

    .collection-row{
        background-color: #320E6A;
        width: 95%;
        flex-direction: column;
        height: auto;
    }
    .collection-row-reverse{
        flex-direction: column-reverse;
    }
    .collection-row-margin{
        margin-top: 50px;
    }
    .collection-row-item{
        width: 100%;
        height: 25%;
    }
    .collection-bg-white, .collection-bg{
        padding: 20px 0; 
    }
    .pag-newproducts-head-title{
        font-size: 1em;
        font-weight: 800;
    }
    .collection-row-item-button{
        width: 100%;
    }


    /*COLLAGE */
    .collage-secondary img{
        margin: 0 4px;
    }
    .collage-description-item{
        font-size: 1em;
    }
    /* NUESTROS CLIENTES*/
    .container-our-clients{
        width: 100%;
        height: 30vh;
        margin-top: 10%;
    }

    /*MARCA DE AGUA*/
    .marcados{
        width: 17%;
        right: 0px;
    }

    /*TRABAJA CON NOSOTROS*/
    .container-workonme{
        margin-top: 15%;
    }
    .conteiner-map-workme{
        display: none;
    }
    .conteiner-form-workme{
        width: 100%;
    }
    .conteiner-form-map{
        height: auto;
        padding: 20px 0;
    }
    .checkbutton-icon{
        width: 50px;
        height: 50px;
    }
    .button-conteiner{
        margin-top: 40px;
    }
    .button-conteiner button{
        width: 90%;
    }
    /*BANNER*/
    .container-banner{
        display: none;
    }

    /*FOOTER*/
    .container-footer{
        height: 70vh;
        display: flex;
        justify-content: center;
    }
    .dashboard-header-conteiner-img{
        object-fit: cover !important;
        display: block;
    }
    .container-footer-text-one{
        margin-top: 30%;
        width: 90%;
        font-size: 1em;
    }
    .container-footer-text-two{
        width: 90%;
        font-size: 1em;
    }
    .container-footer-text-three{
        width: 90%;
        font-size: 1em;
        margin-top: 2%;
    }
    .conteiner-footer-final{
        flex-direction: column;
    }
    .final-item-left{
        width: 100%;
    }
    .final-item-rigth{
        width: 100%;
        margin-top: 4%;
        align-items: start;
    }
    .container-buttons-footer{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .container-buttons-footer > button{
        width: 48%;
        margin: 0 3px;
        height: 45px !important;
        font-size: 0.8em;
    }
}