.form-workme-input{
    margin-top: 10px;
    width: 90%;
    height: auto;
}
.workonme-input-label{
    width: 90%;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: bold;
}
.workonme-input-input{
    width: 97%;
    height: 40px;
    margin-top: 4px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    padding: 0 8px;
}
.workonme-input-textarea{
    width: 97%;
    height: 100px;
    margin-top: 4px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    padding: 10px 8px;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


