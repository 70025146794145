.container-navbar{
    width: 100%;
    height: auto;
    background-color: white;
    position: fixed;
    z-index: 1000;
}
.body-navbar{
    width: 100%;
    height: 88px;
    display: flex;
    justify-content: center;
    background-color: white;
}
.button-style{
    height: 100%;
    margin: 0 4px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.button-style img{
    width: 40px;
    height:auto;
}
.button-style > div{
    color: rgb(210, 210, 210);
}
.button-call{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}
.text-content-call-button{
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.title-button-style{
    text-align: start;
}
.navbar-primary{
    background-color: white;
    height: 60px;
}
.navbar-secondary{
    background-color: #350F6A;
}
.box-item {
    display: flex;
    width: 33%;
    height: 100%;
    align-items: center;
    justify-content: start;
}
.box-item-center{
    display: flex;
    width: 33%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.box-item-left {
    display: flex;
    width: 33%;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: start;
}
.box-item-rigth {
    display: flex;
    width: 33%;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: end;
}
.button-img-logo{
    background-color: transparent;
    cursor: pointer;
    border: none;
    width: 20%;
    margin-left: 5%;
}
.img_logo{
    width: 100%;
    height: 100%;
}
.img_logo_nav{
    width: 100%;
    height: 100%;
}
.btn-nav{
    background-color: transparent;
    color: black;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 80%;
    padding: 4px;
    transition: background-color 0.5s ease;
}

.btn-nav-icon{
    width: 100%;
    height: 30%;
}

.btn-nav:hover{
    background-color: black;
    color: white;
}
.btn-call{
    width: 120px;
}
@media screen and (max-width: 1024px) {
    .title-button-style{
        text-align: center;
    }
}
@media screen and (max-height: 1200px) {
    
    .img_logo_nav{
        width: auto;
        height: 90%;
    }

}
@media screen and (max-width: 720px) {
    
    #div-space{
        display: none;
    }
    .box-item-rigth {
        width: 49.9%;
    }
    .box-item {
        width: 49.9%;
    }
    .btn-call{
        font-size: 0.9em;
    }
    .button-img-logo{
        width: 40%;
        height: 100%;
    }
    .img_logo{
        width: 100%;
        height: 100%;
    }
    .box-item-center{
        width: 50%;
    }
    .box-item{
       display: none;
    }

    .button-style{
        height: 80%;
        margin: 0;
    }
    .img_logo{
        width: 32px !important;
        height: 32px !important;
    }
    .title-button-style{
        font-size: 0.8em;
    }
    .box-show{
        display: flex;
    }
    .img_logo_nav{
        width: 100%;
        height: auto;
    }
}
