.slider-container{
    width: 100%;
    height: 100%;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
}
.horizontal-list {
    display: inline-flex;
    padding: 0;
    margin: 0;
    list-style: none;
    height: 100%;
  }
.slider-container-item{
    width: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slider-container-item > img{
    height: auto;
    width: 60%;
}
.slider-container-item-default{
    width: 50%;
    height: 50%;
    color: white;
}
@media screen and (max-width: 720px) {
    .slider-container-item > img{
        height: 70%;
        width: 80%;
    }
    .slider-container-item{
        width: 160px;
    }
}
@media screen and (max-height: 720px) {
    .slider-container-item > img{
        height: 40%;
        width: 80%;
    }
    .slider-container-item{
        width: 160px;
    }
}